import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const PayrollPeriods = (props) => {
  const classes = useStyles();
  const { handleChange, payroll } = props;
  return (
    <div className={classes.paper}>
      <Typography color="primary" component="h4">
        Select the periods for each company
      </Typography>
      <form
        className={classes.form}
        margin="dense"
        noValidate
        autoComplete="off"
      >
        {payroll.companies.length === 0 ? (
          <Typography
            color="primary"
            component="h4"
            style={{ textAlign: "center" }}
          >
            <b>There are no companies selected</b>
          </Typography>
        ) : (
          payroll.companies.map((item, index) => (
            <Grid key={index} container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Company "
                  name="company_name"
                  value={item && item.name}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel shrink id="defaultIdentifierLabel">
                  Periods
                </InputLabel>
                <Select
                  className={classes.formControl}
                  key="select"
                  fullWidth
                  name="periods"
                  label="Periods"
                  value={item && item.periods}
                  onChange={handleChange(index)}
                >
                  {item.availablePeriods.map((period) => (
                    <MenuItem key={period.pk} value={period.pk}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                          <item>
                            <Typography variant="button" color="initial">
                              ID :
                            </Typography>
                            <Typography variant="overline" color="initial">
                              {period.pk}
                            </Typography>
                          </item>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <item>
                            <Typography variant="button" color="initial">
                              Status :
                            </Typography>
                            {period.status === "COMPLETED" ? (
                              <Typography variant="button" style={{color:"green"}}>
                                {period.status}
                              </Typography>
                            ) : (
                              <Typography variant="overline" color="initial">
                                {period.status}
                              </Typography>
                            )}
                          </item>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <item>
                            <Typography variant="button" color="initial">
                              Start Date:
                            </Typography>
                            <Typography variant="overline" color="initial">
                              {period.start_date.split("T")[0]}
                            </Typography>
                          </item>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <item>
                            <Typography variant="button" color="initial">
                              End Date :
                            </Typography>
                            <Typography variant="overline" color="initial">
                              {period.end_date.split("T")[0]}
                            </Typography>
                          </item>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <item>
                            <Typography variant="button" color="initial">
                              Check Date :
                            </Typography>
                            <Typography variant="overline" color="initial">
                              {period.check_date.split("T")[0]}
                            </Typography>
                          </item>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                      <br></br>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          ))
        )}
      </form>
    </div>
  );
};
