import React from "react";
import { Container } from "@material-ui/core";
import ReportingGrid from "./ReportingGrid";

export const Reporting = (props) => {
  const { selectedCompanies, dateFrom, dateTo, rangeFrom, rangeTo } =
    props.location.state;

  return (
    <>
      <Container>
        <ReportingGrid
          selectedCompanies={selectedCompanies}
          dateFrom={dateFrom}
          dateTo={dateTo}
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
        />
      </Container>
    </>
  );
};
