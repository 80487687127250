import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Typography,
  Container,
  Button,
  makeStyles,
  Modal,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import InfiniteTable from "./InfiniteTable";
import { backend, API_URL } from "../backend_api";

const columns = [
  {
    id: "pk",
    numeric: false,
    editable: "/companies",
    editablePk: "pk",
    disablePadding: false,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "pos_name",
    numeric: false,
    editable: "/pos",
    editablePk: "pk",
    disablePadding: false,
    label: "POS",
    statusPk: "pos_status_color",
  },
  {
    id: "payroll_name",
    numeric: false,
    editable: "/payroll",
    editablePk: "pk",
    disablePadding: false,
    label: "Payroll",
    statusPk: "payroll_status_color",
  },
  {
    id: "account_name",
    numeric: false,
    editable: "/accounting",
    editablePk: "pk",
    disablePadding: false,
    label: "Accounting",
    statusPk: "accounting_status_color",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(2),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    top: "20%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const Companies = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successPos, setSuccessPos] = useState(false);
  const [successPayroll, setSuccessPayroll] = useState(false);
  const [successPeriods, setSuccessPeriods] = useState(false);
  const [successAccount, setSuccessAccount] = useState(false);
  const [pks, setPks] = useState([]);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleButtonClick = () => {
    sendRequest();
    if (!loading) {
      setSuccess(false);
      setLoading(true);
    }
    resolveRequest();
  };

  const loadingModalContent = (
    <div className={classes.modal}>
      <div align="center">
        <CircularProgress />
        <div>{"The categories are updating"}</div>
      </div>
    </div>
  );

  const resolveRequest = () => {
    setSuccess(false);
  };

  const refresh = () => {
    window.location.reload(false);
  };

  const sendRequest = async () => {
    setLoading(true);
    pos();
    accounting();
    payrollPeriods();
    // payroll();
  };

  const pos = async () => {
    await backend
      .post(`${API_URL}data/api/refreshpos/`, { refresh_pk_list: pks })
      .then((response) => {
        setSuccessPos(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const payrollPeriods = () => {
    Promise.all(
      pks.map(async (item) => {
        await backend
          .post(`${API_URL}data/api/refreshpayroll/`, {
            refresh_pk_list: [item],
          })
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });

        await backend
          .post(`${API_URL}data/api/refreshpayrollperiods/`, {
            refresh_pk_list: [item],
          })
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });
      })
    ).then((response) => {
      console.log(response);
      setSuccessPayroll(true);
      setSuccessPeriods(true);
    });
  };

  /* const payroll = async () => {
    await backend
      .post(`${API_URL}data/api/refreshpayroll/`, { refresh_pk_list: pks })
      .then((response) => {
        periods();
        setSuccessPayroll(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }; */

  /* const periods = async () => {
    await backend
      .post(`${API_URL}data/api/refreshpayrollperiods/`, {
        refresh_pk_list: pks,
      })
      .then((response) => {
        setSuccessPeriods(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }; */

  const accounting = async () => {
    await backend
      .post(`${API_URL}data/api/refreshaccounts/`, {
        refresh_pk_list: pks,
      })
      .then((response) => {
        setSuccessAccount(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (successPos && successPayroll && successAccount && successPeriods) {
      setLoading(false);
      setSuccess(true);
      refresh();
    }
  }, [successPayroll, successPos, successAccount, successPeriods]);

  useEffect(() => {
    const companyGet = async () => {
      await backend
        .get(`${API_URL}data/api/companies/`)
        .then(({ data }) => {
          const dataMap = data.length ? data : data.results;

          setPks(
            dataMap
              .filter((item) => item.pk !== 32 && item.pk !== 68)
              .map((item) => item.pk)
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };
    companyGet();
  }, []);

  return (
    <>
      <Container style={{ textAlign: "right" }}>
        <Modal open={loading}>{loadingModalContent}</Modal>
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="primary"
          className={buttonClassname}
          disabled={loading}
          onClick={handleButtonClick}
        >
          <Typography variant="subtitle1" className="text-white">
            Update Categories
          </Typography>
        </Button>
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="primary"
        >
          <Link to="/companies/add" className="link" color="inherit">
            <Typography variant="subtitle1" className="text-white">
              Add Company
            </Typography>
          </Link>
        </Button>
      </Container>
      <Container>
        <InfiniteTable
          apiep="data/api/companies"
          columns={columns}
          dataMapByColumn="id"
          deletable={false}
          editable={true}
          filterable={true}
          label="Companies"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
