import "./splash.css";
import { useContext } from "react";
import { AuthenticationContext } from "../auth/authcontext";

import { Box, Container } from "@material-ui/core";

import LoginModal from "./loginmodal";
import AppTopBar from "./AppTopBar";


const Splash = (props) => {
  let auth = useContext(AuthenticationContext);

  if (auth.user) {
    return <AppTopBar />;
  }

  return (
    <>
      <Container style={{ textAlign: "center" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <h1 style={{ fontSize: "4em", marginBottom: "1em" }}>a4e</h1>
          <h2>Welcome to Amrit’s Automation and Analysis Engine</h2>
          <p>Please Login to continue.</p>
          <LoginModal buttonLabel="Login" className="splash-login-modal" />
        </Box>
      </Container>
    </>
  );
};

export default Splash;
