import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  useTheme,
  Container,
  Button,
} from "@material-ui/core/";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 385,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export const ReportingSettings = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const cDate = new Date().toISOString();

  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [dateFrom, setDateFrom] = useState(cDate);
  const [dateTo, setDateTo] = useState(cDate);
  const [rangeFrom, setRangeFrom] = useState("");
  const [rangeTo, setRangeTo] = useState("");

  const handleSelectedCompaniesChange = (e) => {
    const { value } = e.target;

    setSelectedCompanies(value);
  };

  const handleRangeFromChange = (e) => {
    console.log(e);
    const { value } = e.target;

    setRangeFrom(value);
  };

  const handleRangeToChange = (e) => {
    const { value } = e.target;

    setRangeTo(value);
  };

  const handleDateFromChange = (date) => {
    setDateFrom(date.toISOString());
  };

  const handleDateToChange = (date) => {
    setDateTo(date.toISOString());
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getStyles = (name, theme) => {
    return {
      fontWeight:
        selectedCompanies.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  useEffect(() => {
    const getCompanies = async () => {
      await backend.get(`${API_URL}data/api/companies`).then(({ data }) => {
        const dataMap = data.length ? data : data.results;

        const companiesMap = dataMap.map((item) => {
          return {
            pk: item.pk,
            name: item.name,
          };
        });
        setCompanies(companiesMap);
      });
    };

    getCompanies();
  }, []);

  useEffect(() => {
    const getAccounting = async () => {
      const companyId = companies[0].pk || 3;

      await backend
        .get(`${API_URL}data/api/accounting/${companyId}`)
        .then(({ data }) => {
          let dataMap = [];

          Object.keys(data.accounts).forEach((item) => {
            dataMap.push({
              number: item,
              name: data.accounts[item],
            });
          });
          setAccounts(dataMap);
        });
    };

    if (companies.length) {
      getAccounting();
    }
  }, [companies]);

  return (
    <div className={classes.paper}>
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <InputLabel shrink id="companies">
              Companies
            </InputLabel>
            <Select
              fullWidth
              multiple
              label="Companies"
              onChange={handleSelectedCompaniesChange}
              name="companies"
              input={<Input />}
              value={companies && selectedCompanies}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((item) => (
                    <Chip
                      key={item.pk + "chip"}
                      label={item.name}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {companies.map((option) => (
                <MenuItem
                  key={option.pk}
                  value={option}
                  style={getStyles(option.name, theme)}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                label="Start date"
                value={dateFrom && dateFrom}
                onChange={handleDateFromChange}
                KeyboardButtonProps={{
                  "aria-label": "change date from",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                label="End date"
                value={dateTo && dateTo}
                onChange={handleDateToChange}
                KeyboardButtonProps={{
                  "aria-label": "change date to",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputLabel shrink id="companies">
              Start range
            </InputLabel>
            <Select
              fullWidth
              label="Start range"
              onChange={handleRangeFromChange}
              name="start_range"
              input={<Input />}
              value={accounts && rangeFrom}
              MenuProps={MenuProps}
            >
              {accounts.map((option) => (
                <MenuItem key={option.number} value={option.number}>
                  {`${option.number} - (${option.name})`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputLabel shrink id="companies">
              End range
            </InputLabel>
            <Select
              fullWidth
              label="End range"
              onChange={handleRangeToChange}
              name="end_range"
              input={<Input />}
              value={accounts && rangeTo}
              MenuProps={MenuProps}
            >
              {accounts.map((option) => (
                <MenuItem key={option.number} value={option.number}>
                  {`${option.number} - (${option.name})`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !selectedCompanies.length ||
                rangeFrom > rangeTo ||
                dateFrom > dateTo
              }
            >
              <Link
                to={{
                  pathname: "/reporting",
                  state: {
                    selectedCompanies,
                    dateFrom,
                    dateTo,
                    rangeFrom,
                    rangeTo,
                  },
                }}
                className="link"
                color="inherit"
              >
                <Typography variant="subtitle1" className="text-white">
                  Accounting Balance
                </Typography>
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
