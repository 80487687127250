import React, { useState, Fragment, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { PosForm } from "./PosForm";
import { PosDates } from "./PosDates";

import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const Pos = (props) => {
  const classes = useStyles();
  const steps = ["Select Company", "Select range of dates"];

  const [activeStep, setActiveStep] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [pos, setPos] = useState({ companies: [] });
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [finishedFlag, setFinishedFlag] = useState(false);

  const [selectedDateFrom, setSelectedDateFrom] = useState(
    new Date().toISOString()
  );
  const [selectedDateTo, setSelectedDateTo] = useState(
    new Date().toISOString()
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPos((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateFromChange = (date) => {
    setSelectedDateFrom(date.toISOString());
  };

  const handleDateToChange = (date) => {
    setSelectedDateTo(date.toISOString());
  };

  useEffect(() => {
    const getCompanies = async () => {
      await backend
        .get(`${API_URL}data/api/companies?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => ({
            pk: item.pk,
            name: item.name,
          }));
          setCompanies(dataMap);
        });
    };
    getCompanies();
  }, []);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PosForm
            {...props}
            pos={pos}
            companies={companies}
            handleChange={handleChange}
          />
        );
      case 1:
        return (
          <PosDates
            {...props}
            selectedDateFrom={selectedDateFrom}
            selectedDateTo={selectedDateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Are you sure to export all sales to the accounting system?
          <div align="right">
            <Button
              onClick={() => toggleConfirmModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendRequest()}
              className={classes.button}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div align="center">
            <CircularProgress />
            <div>
              Exporting sales <br />
              This may take some time relative to the number of sales.
            </div>
          </div>
          {errorInfo.error ? (
            <>
              <br />
              <br />
              There was an error while exporting the sales
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  wordBreak: "break-word",
                  maxHeight: 160,
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                <b>Error:</b>
                <br />
                {errorInfo.message}
              </span>
              <div align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resolveRequest()}
                  className={classes.button}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          The sales have been successfully exported
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const resolveRequest = () => {
    setFinishedFlag(false);
    toggleConfirmModal();
    props.history.push("/companies");
  };

  const sendRequest = async () => {
    setLoadingFlag(true);
    let companiesArray = pos.companies.map((item) => item.pk);
    await backend
      .post(
        `${API_URL}data/api/sendsales/?from_date=${selectedDateFrom}&to_date=${selectedDateTo}`,
        { pk_list: companiesArray }
      )
      .then((response) => {
        setLoadingFlag(false);
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  return (
    <div>
      <Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Send Sales
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Modal open={confirmModal} onClose={toggleConfirmModal}>
              {confirmModalContent}
            </Modal>
            <Fragment>
              {activeStep === steps.length ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </Paper>
        </main>
      </Fragment>
    </div>
  );
};
