import React, { Fragment, useState, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
} from "@material-ui/core";
import { CompanyForm } from "./CompanyForm";
import { CompanyPOS } from "./CompanyPOS";
import { CompanyPayroll } from "./CompanyPayroll";
import { CompanyAccounting } from "./CompanyAccounting";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const CompanyProcess = (props) => {
  const classes = useStyles();
  const steps = [
    "Company Information",
    "POS Information",
    "Payroll Information",
    "Accounting Information",
  ];
  let companyId = props.match.params.id;

  const [company, setCompany] = useState({
    name: "",
    pos_name: "",
    pos_company_id: "",
    pos: "",
    payroll_name: "",
    payroll_company_id: "",
    payroll: "",
    accounting_name: "",
    accounting: "",
    accounting_username: "",
    user_password: "",
    accounting_entity_id: "",
    accounting_company_id: "",
    sales_journal_id: "",
    payroll_journal_id: "",
  });
  const [companyCopy, setCompanyCopy] = useState({
    name: "",
    pos_name: "",
    pos_company_id: "",
    pos: "",
    payroll_name: "",
    payroll_company_id: "",
    payroll: "",
    accounting_name: "",
    accounting: "",
    accounting_username: "",
    user_password: "",
    accounting_entity_id: "",
    accounting_company_id: "",
    sales_journal_id: "",
    payroll_journal_id: "",
  });

  const [pos, setPos] = useState([]);
  const [payroll, setPayroll] = useState([]);
  const [accounting, setAccounting] = useState([]);
  const [accountingRequested, setAccountingRequested] = useState(false);
  const [payrollRequested, setPayrollRequested] = useState(false);
  const [posRequested, setPosRequested] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CompanyForm
            {...props}
            company={company}
            handleChange={handleChange}
          />
        );
      case 1:
        return (
          <CompanyPOS
            {...props}
            company={company}
            pos={pos}
            handleChange={handleChange}
          />
        );
      case 2:
        return (
          <CompanyPayroll
            {...props}
            company={company}
            payroll={payroll}
            handleChange={handleChange}
          />
        );
      case 3:
        return (
          <CompanyAccounting
            {...props}
            company={company}
            accounting={accounting}
            handleChange={handleChange}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const handleCancel = () => {
    props.history.push("/companies");
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCompany((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      Are you sure you want to {!isNaN(companyId) ? " update " : " add "}
      the company <b>{company.name}</b>
      <div align="right">
        <Button onClick={() => toggleConfirmModal()} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => sendRequest()}
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const toggleResponseModal = () => setResponseModal(!responseModal);

  const responseModalContent = (
    <div className={classes.modal}>
      {errorInfo.error ? (
        <>
          There was an error
          {!isNaN(companyId) ? " updating " : " adding "}
          the company <b>{company.name}</b>
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Error:</b>
            <br />
            {errorInfo.message}
          </span>
        </>
      ) : (
        <>
          Company <b>{company.name}</b> has been successfully
          {!isNaN(companyId) ? " updated " : " added "}
        </>
      )}
      <div align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => resolveRequest()}
          className={classes.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const resolveRequest = () => {
    props.history.push("/companies");
  };

  const sendRequest = async () => {
    let pkInfo = {
      pos: "",
      payroll: "",
      accounting: "",
    };
    if (companyId && !isNaN(companyId)) {
      sendPos(pkInfo);
    } else {
      let companyObj = {
        name: company.name,
        ...pkInfo,
      };
      await backend
        .post(`${API_URL + "data/api/companies/"}`, [companyObj])
        .then((response) => {
          companyId = response.data[0].pk;
          sendPos(pkInfo);
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    }
  };

  const sendPos = async (pkInfo) => {
    if (
      company.pos_name === companyCopy.pos_name &&
      company.pos_company_id === companyCopy.pos_company_id
    ) {
      pkInfo = { ...pkInfo, pos: company.pos };
      sendPayroll(pkInfo);
    } else {
      let posObj = {
        name: company.pos_name,
        company_id_at_pos: company.pos_company_id,
      };
      if (company.pos === "") {
        await backend
          .post(`${API_URL}data/api/pos/`, [posObj])
          .then((response) => {
            pkInfo = { ...pkInfo, pos: response.data[0].pk };
            sendPayroll(pkInfo);
          })
          .catch((error) => {
            console.log(error);
            setErrorInfo({
              error: true,
              message: JSON.stringify(error.response.data),
            });
            toggleResponseModal();
          });
      } else {
        await backend
          .put(`${API_URL}data/api/pos/${company.pos}`, posObj)
          .then((response) => {
            pkInfo = { ...pkInfo, pos: response.data.pk };
            sendPayroll(pkInfo);
          })
          .catch((error) => {
            console.log(error);
            setErrorInfo({
              error: true,
              message: JSON.stringify(error.response.data),
            });
            toggleResponseModal();
          });
      }
    }
  };

  const sendPayroll = async (pkInfo) => {
    if (
      company.payroll_name === companyCopy.payroll_name &&
      company.payroll_company_id === companyCopy.payroll_company_id
    ) {
      pkInfo = { ...pkInfo, payroll: company.payroll };
      sendAccounting(pkInfo);
    } else {
      let payrollObj = {
        name: company.payroll_name,
        company_id_at_payroll: company.payroll_company_id,
      };
      if (company.payroll === "") {
        await backend
          .post(`${API_URL}data/api/payroll/`, [payrollObj])
          .then((response) => {
            pkInfo = { ...pkInfo, payroll: response.data[0].pk };
            sendAccounting(pkInfo);
          })
          .catch((error) => {
            console.log(error);
            setErrorInfo({
              error: true,
              message: JSON.stringify(error.response.data),
            });
            toggleResponseModal();
          });
      } else {
        await backend
          .put(`${API_URL}data/api/payroll/${company.payroll}`, payrollObj)
          .then((response) => {
            pkInfo = { ...pkInfo, payroll: response.data.pk };
            sendAccounting(pkInfo);
          })
          .catch((error) => {
            console.log(error);
            setErrorInfo({
              error: true,
              message: JSON.stringify(error.response.data),
            });
            toggleResponseModal();
          });
      }
    }
  };

  const sendAccounting = async (pkInfo) => {
    if (
      company.accounting_name === companyCopy.accounting_name &&
      company.accounting_company_id === companyCopy.accounting_company_id &&
      company.accounting_entity_id === companyCopy.accounting_entity_id &&
      company.accounting_username === companyCopy.accounting_username &&
      company.user_password === companyCopy.user_password &&
      company.sales_journal_id === companyCopy.sales_journal_id &&
      company.payroll_journal_id === companyCopy.payroll_journal_id
    ) {
      pkInfo = { ...pkInfo, accounting: company.accounting };
      sendCompany(pkInfo);
    } else {
      let accountingObj = {
        name: company.accounting_name,
        company_id_at_account: company.accounting_company_id,
        entity_id_at_account: company.accounting_entity_id,
        user_id_at_account: company.accounting_username,
        user_password: company.user_password,
        sales_journal_id: company.sales_journal_id,
        payroll_journal_id: company.payroll_journal_id,
        
      };
      if (company.accounting === "") {
        await backend
          .post(`${API_URL}data/api/accounting/`, [accountingObj])
          .then((response) => {
            pkInfo = { ...pkInfo, accounting: response.data[0].pk };
            sendCompany(pkInfo);
          })
          .catch((error) => {
            console.log(error);
            setErrorInfo({
              error: true,
              message: JSON.stringify(error.response.data),
            });
            toggleResponseModal();
          });
      } else {
        await backend
          .put(
            `${API_URL}data/api/accounting/${company.accounting}`,
            accountingObj
          )
          .then((response) => {
            pkInfo = { ...pkInfo, accounting: response.data.pk };
            sendCompany(pkInfo);
          })
          .catch((error) => {
            console.log(error);
            setErrorInfo({
              error: true,
              message: JSON.stringify(error.response.data),
            });
            toggleResponseModal();
          });
      }
    }
  };

  const sendCompany = async (pkInfo) => {
    let companyObj = {
      name: company.name,
      pos: pkInfo.pos,
      payroll: pkInfo.payroll,
      accounting: pkInfo.accounting,
    };
    await backend
      .put(`${API_URL}data/api/companies/${companyId}`, companyObj)
      .then((response) => {
        toggleResponseModal();
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
        toggleResponseModal();
      });
  };

  useEffect(() => {
    const supportedSystemsGet = async () => {
      await backend
        .get(`${API_URL}data/api/supportedsystems/`)
        .then(({ data }) => {
          setPos(data.pos);
          setPayroll(data.payroll);
          setAccounting(data.accounting);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const companyGet = async () => {
      await backend
        .get(`${API_URL}data/api/companies/${companyId}`)
        .then((response) => {
          let companyObj = {
            name: response.data.name || "",
            pos_name: response.data.pos_name || "",
            pos: response.data.pos || "",
            payroll_name: response.data.payroll_name || "",
            payroll: response.data.payroll || "",
            accounting_name: response.data.account_name || "",
            accounting: response.data.accounting || "",
            accounting_username: "",
            user_password: "",
            accounting_entity_id: "",
            accounting_company_id: "",
            sales_journal_id: "",
            payroll_journal_id: "",
          };
          setCompany((prevState) => ({ ...prevState, ...companyObj }));
          setCompanyCopy((prevState) => ({ ...prevState, ...companyObj }));
        })
        .catch((error) => {
          console.log(error);
        });
    };

    supportedSystemsGet();
    if (!isNaN(companyId)) {
      companyGet(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    const accountingDetailGet = async () => {
      await backend
        .get(`${API_URL}data/api/accounting/${company.accounting}`)
        .then((response) => {
          setCompany((prevState) => ({
            ...prevState,
            accounting_username: response.data.user_id_at_account || "",
            user_password: response.data.user_password || "",
            accounting_entity_id: response.data.entity_id_at_account || "",
            accounting_company_id: response.data.company_id_at_account || "",
            sales_journal_id: response.data.sales_journal_id || "",
            payroll_journal_id: response.data.payroll_journal_id || "",
          }));
          setCompanyCopy((prevState) => ({
            ...prevState,
            accounting_username: response.data.user_id_at_account || "",
            user_password: response.data.user_password || "",
            accounting_entity_id: response.data.entity_id_at_account || "",
            accounting_company_id: response.data.company_id_at_account || "",
            sales_journal_id: response.data.sales_journal_id || "",
            payroll_journal_id: response.data.payroll_journal_id || "",
          }));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setAccountingRequested(true);
        });
    };

    if (company.accounting !== "" && !accountingRequested) {
      accountingDetailGet();
    }
  }, [accountingRequested, company]);

  useEffect(() => {
    const payrollDetailGet = async () => {
      await backend
        .get(`${API_URL}data/api/payroll/${company.payroll}`)
        .then((response) => {
          setCompany((prevState) => ({
            ...prevState,
            payroll_company_id: response.data.company_id_at_payroll || "",
          }));
          setCompanyCopy((prevState) => ({
            ...prevState,
            payroll_company_id: response.data.company_id_at_payroll || "",
          }));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setPayrollRequested(true);
        });
    };

    if (
      company.payroll !== "" &&
      company.payroll_company_id === "" &&
      !payrollRequested
    ) {
      payrollDetailGet();
    }
  }, [payrollRequested, company]);

  useEffect(() => {
    const posDetailGet = async () => {
      await backend
        .get(`${API_URL}data/api/pos/${company.pos}`)
        .then((response) => {
          setCompany((prevState) => ({
            ...prevState,
            pos_company_id: response.data.company_id_at_pos || "",
          }));
          setCompanyCopy((prevState) => ({
            ...prevState,
            pos_company_id: response.data.company_id_at_pos || "",
          }));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setPosRequested(true);
        });
    };

    if (company.pos !== "" && company.pos_company_id === "" && !posRequested) {
      posDetailGet();
    }
  }, [posRequested, company]);

  return (
    <div>
      <>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Company
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              <Modal open={confirmModal} onClose={toggleConfirmModal}>
                {confirmModalContent}
              </Modal>
              <Modal open={responseModal} onClose={toggleResponseModal}>
                {responseModalContent}
              </Modal>
              {getStepContent(activeStep)}
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  onClick={handleCancel}
                  className={classes.button}
                >
                  Cancel
                </Button>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? "Save" : "Next"}
                </Button>
              </div>
            </>
          </Paper>
        </main>
      </>
    </div>
  );
};
