import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Collapse,
  IconButton,
} from "@material-ui/core";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import { backend, API_URL } from "../backend_api";

export const ReportingGrid = (props) => {
  const { selectedCompanies, dateFrom, dateTo, rangeFrom, rangeTo } = props;

  const [accounting, setAccounting] = useState(undefined);
  const [accountingCategories, setAccountingCategories] = useState(undefined);
  const [accountingCategoriesDisplay, setAccountingCategoriesDisplay] =
    useState(undefined);
  const [companies, setCompanies] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [rows, setRows] = useState(undefined);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [dataScrollLeft, setDataScrollLeft] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const getAccounting = async () => {
      const companyId = selectedCompanies[0].pk;
      await backend
        .get(`${API_URL}data/api/accounting/${companyId}`)
        .then(({ data }) => {
          const dataMap = Object.keys(data.accounts);
          let accountingDict = {};

          dataMap
            .filter(
              (item) =>
                item >= rangeFrom && (rangeTo > 0 ? item <= rangeTo : true)
            )
            .forEach((item) => (accountingDict[item] = data.accounts[item]));

          const accountingCategoriesMap = Object.keys(
            data.account_report_category
          );
          let accountingCategoriesDict = {};
          let accountingCategoriesDisplayDict = {};

          accountingCategoriesMap
            .filter(
              (item) =>
                item >= rangeFrom && (rangeTo > 0 ? item <= rangeTo : true)
            )
            .forEach((item) => {
              let categoryGroup =
                data.account_report_category[item] === null
                  ? "UNMAPPED"
                  : data.account_report_category[item];
              const categoryGroupExists =
                accountingCategoriesDict[categoryGroup];

              if (categoryGroupExists) {
                accountingCategoriesDict[categoryGroup] = {
                  ...accountingCategoriesDict[categoryGroup],
                  accountingGroupItems: [
                    ...accountingCategoriesDict[categoryGroup]
                      .accountingGroupItems,
                    item,
                  ],
                };
              } else {
                accountingCategoriesDict[categoryGroup] = {
                  accountingNumber: item,
                  accountingName: categoryGroup,
                  accountingGroupItems: [item],
                };
              }

              accountingCategoriesDisplayDict[categoryGroup] = true;
            });

          console.log(
            accountingDict,
            accountingCategoriesDict,
            accountingCategoriesDisplayDict
          );

          setAccounting(accountingDict);
          setAccountingCategories(accountingCategoriesDict);
          setAccountingCategoriesDisplay(accountingCategoriesDisplayDict);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getCompanies = async () => {
      await backend
        .get(`${API_URL}data/api/companies/`)
        .then(({ data }) => {
          const dataMap = data.length ? data : data.results;

          let companiesDict = {};
          dataMap.forEach((item) => (companiesDict[item.pk] = item.name));

          setCompanies(companiesDict);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getAccounting();
    getCompanies();
  }, [rangeFrom, rangeTo, selectedCompanies]);

  useEffect(() => {
    const getTitle = () => {
      setTitle(
        `Account balance from ${dateFrom.split("T")[0]} to ${
          dateTo.split("T")[0]
        }`
      );
    };
    const getAccountingBalance = async () => {
      await backend
        .post(`${API_URL}data/api/accountbalances/`, {
          from_date: dateFrom,
          to_date: dateTo,
          pk_list: selectedCompanies.map((item) => item.pk),
          start_range: rangeFrom,
          end_range: rangeTo,
        })
        .then(({ data }) => {
          setData(data);
        });
    };

    if (accounting && accountingCategories && companies && !data) {
      getTitle();
      getAccountingBalance();
    }
  }, [
    accounting,
    accountingCategories,
    companies,
    data,
    dateFrom,
    dateTo,
    rangeFrom,
    rangeTo,
    selectedCompanies,
  ]);

  useEffect(() => {
    const getRows = () => {
      const companiesPks = Object.keys(data);
      let rowsDict = {};
      companiesPks.forEach((cItem) => {
        const rowsPks = Object.keys(data[cItem]);
        rowsPks.forEach(
          (rItem) =>
            (rowsDict[rItem] = isNaN(rowsDict[rItem]) ? 1 : rowsDict[rItem] + 1)
        );
      });

      setRows(rowsDict);
    };

    const getTotals = () => {
      setAccountingCategories((prevState) => {
        const companiesPks = Object.keys(data);

        let accountingCategoriesTotalsDict = {};
        Object.keys(prevState).forEach((acItem) => {
          const acGroupItems = prevState[acItem].accountingGroupItems;

          let companyTotal = {};
          companiesPks.forEach((cItem) => {
            let total = 0;
            const companyValues = data[cItem];
            acGroupItems.forEach((acgiItem) => {
              total =
                total + (companyValues[acgiItem] ? companyValues[acgiItem] : 0);
            });
            companyTotal[cItem] = total.toFixed(2);
          });

          accountingCategoriesTotalsDict[acItem] = companyTotal;
        });

        let newState = {};
        Object.keys(prevState).forEach(
          (pItem) =>
            (newState[pItem] = {
              ...prevState[pItem],
              accountingTotal: accountingCategoriesTotalsDict[pItem],
            })
        );

        return newState;
      });
    };

    if (data) {
      getRows();
      getTotals();
    }
  }, [data]);

  const dataScrolling = (e) => {
    console.log(e.target.className, e.target.scrollLeft);
    e.preventDefault();
    e.stopPropagation();
    const fakeScrollbar = document.querySelector(".rg-fakescroll-normal");
    fakeScrollbar.scrollLeft = e.target.scrollLeft;
    const dataScrollbar = document.querySelector(".rg-data-normal");
    if (dataScrollbar.scrollLeft > 0) {
      setDataScrollLeft(0);
    }
    setScrollLeft(e.target.scrollLeft);
  };

  const dataScrollingFake = (e) => {
    console.log(e.target.className, e.target.scrollLeft);
    e.preventDefault();
    e.stopPropagation();
    const dataScrollbar = document.querySelector(".rg-data-normal");
    if (dataScrollbar.scrollLeft > 0) {
      setDataScrollLeft(0);
    } else {
      setDataScrollLeft(e.target.scrollLeft);
    }
    setScrollLeft(e.target.scrollLeft);
  };

  const toggleACDisplay = (acItem) =>
    setAccountingCategoriesDisplay((prevState) => ({
      ...prevState,
      [acItem]: !prevState[acItem],
    }));

  return (
    <>
      <Typography variant="h6" component="div">
        {title}
      </Typography>
      <Container style={{ overflow: "hidden" }}>
        {!rows && (
          <div align="center">
            <CircularProgress />
            <div>{"Loading accounting balance..."}</div>
          </div>
        )}
        {rows && (
          <>
            <div className="rg-root" style={{ width: 1184 }}>
              <div
                className="rg-header"
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontWeight: "bold",
                  height: 48,
                  background: "#fff",
                  textAlign: "center",
                }}
              >
                <div
                  className="rg-header-pinned"
                  style={{
                    minWidth: 250,
                    minHeight: 48,
                    borderRight: "1px solid #eee",
                  }}
                >
                  <div style={{ display: "inline-block", width: 250 }}></div>
                </div>
                <div
                  className="rg-header-normal"
                  style={{
                    overflow: "hidden",
                    flex: "1 1 auto",
                    borderBottom: "1px solid #eee",
                  }}
                >
                  <div
                    className="rg-header-normal-container"
                    style={{
                      width: selectedCompanies.length * 150,
                      transform: `translateX(-${scrollLeft}px)`,
                    }}
                  >
                    {selectedCompanies.map((item) => (
                      <div
                        key={item.pk}
                        style={{
                          display: "inline-block",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: 150,
                        }}
                        title={companies[item.pk]}
                      >
                        {companies[item.pk]}
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="rg-header-spacer"
                  style={{ minWidth: 17, minHeight: 48 }}
                ></div>
              </div>
              <div
                className="rg-data"
                style={{
                  display: "flex",
                  height: 500,
                  overflow: "hidden auto",
                }}
              >
                <div
                  className="rg-data-pinned"
                  style={{
                    height:
                      Object.keys(rows).length * 24 +
                      Object.keys(accountingCategories).length * 48,
                    minWidth: 250,
                    maxWidth: 250,
                    borderRight: "1px solid #eee",
                  }}
                >
                  {Object.keys(accountingCategories).map((acItem) => {
                    let acUsed = Object.keys(rows).some((rItem) =>
                      accountingCategories[
                        acItem
                      ].accountingGroupItems.includes(rItem)
                    );

                    return acUsed ? (
                      <Fragment key={acItem.toLocaleLowerCase()}>
                        <div
                          className="ag-group"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            fontWeight: "bold",
                          }}
                        >
                          <IconButton onClick={() => toggleACDisplay(acItem)}>
                            {accountingCategoriesDisplay[acItem] ? (
                              <RemoveCircleOutlineIcon />
                            ) : (
                              <AddCircleOutlineIcon />
                            )}
                          </IconButton>
                          {acItem}
                        </div>
                        <Collapse in={accountingCategoriesDisplay[acItem]}>
                          {Object.keys(rows).map((rItem) => {
                            let rUsed =
                              accountingCategories[
                                acItem
                              ].accountingGroupItems.includes(rItem);

                            return rUsed ? (
                              <div
                                className="ag-group-item"
                                key={acItem.toLocaleLowerCase() + "-" + rItem}
                                style={{
                                  marginLeft: 48,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                                title={accounting[rItem]}
                              >
                                {accounting[rItem]}
                              </div>
                            ) : (
                              <></>
                            );
                          })}
                        </Collapse>
                      </Fragment>
                    ) : (
                      <></>
                    );
                  })}
                </div>
                <div
                  className="rg-data-normal-wrapper"
                  style={{
                    height:
                      Object.keys(rows).length * 24 +
                      Object.keys(accountingCategories).length * 48,
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="rg-data-normal"
                    style={{
                      overflowX: "auto",
                      height: "calc(100% + 17px",
                    }}
                    onScroll={dataScrolling}
                  >
                    <div
                      className="rg-data-normal-container"
                      style={{
                        height:
                          Object.keys(rows).length * 24 +
                          Object.keys(accountingCategories).length * 48,
                        width: selectedCompanies.length * 150,
                        transform: `translateX(-${dataScrollLeft}px)`,
                      }}
                    >
                      {Object.keys(accountingCategories).map((acItem) => {
                        let acUsed = Object.keys(rows).some((rItem) =>
                          accountingCategories[
                            acItem
                          ].accountingGroupItems.includes(rItem)
                        );

                        return acUsed ? (
                          <Fragment key={acItem.toLocaleLowerCase()}>
                            <div
                              className="ag-group"
                              style={{
                                alignItems: "center",
                                display: "flex",
                                fontWeight: "bold",
                                height: 48,
                              }}
                            >
                              {selectedCompanies.map((cItem) => {
                                const categoryTotals =
                                  accountingCategories[acItem].accountingTotal;

                                const categoryTotal =
                                  categoryTotals && +categoryTotals[cItem.pk];

                                return (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: 150,
                                      textAlign: "center",
                                      paddingRight: 10,
                                    }}
                                  >{`${categoryTotal.toLocaleString(
                                    "en-US"
                                  )}`}</div>
                                );
                              })}
                            </div>
                            <Collapse in={accountingCategoriesDisplay[acItem]}>
                              {Object.keys(rows).map((rItem) => {
                                let rUsed =
                                  accountingCategories[
                                    acItem
                                  ].accountingGroupItems.includes(rItem);

                                return rUsed ? (
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      width: selectedCompanies.length * 150,
                                    }}
                                  >
                                    {selectedCompanies.map((cItem) => {
                                      const companyBalance =
                                        data[cItem.pk] || 0;
                                      const rowBalance =
                                        companyBalance[rItem] || 0;

                                      return (
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: 150,
                                            textAlign: "center",
                                            paddingRight: 10,
                                          }}
                                        >{`${rowBalance.toLocaleString(
                                          "en-US"
                                        )}`}</div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <></>
                                );
                              })}
                            </Collapse>
                          </Fragment>
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="rg-fakescroll"
                style={{ display: "flex", maxHeight: 17 }}
              >
                <div
                  className="rg-fakescroll-pinned"
                  style={{ minWidth: 250, overflow: "scroll hidden" }}
                ></div>
                <div
                  className="rg-fakescroll-normal"
                  style={{
                    overflow: "scroll hidden",
                    flex: "1 1 auto",
                  }}
                  onScroll={dataScrollingFake}
                >
                  <div
                    className="rg-fakescroll-normal-container"
                    style={{
                      minWidth: selectedCompanies.length * 150,
                      minHeight: 17,
                    }}
                  ></div>
                </div>
                <div
                  className="rg-fakescroll-spacer"
                  style={{ minWidth: 17, minHeight: 17 }}
                ></div>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default ReportingGrid;
