import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@material-ui/core";

import * as Icons from "@material-ui/icons";

import { Switch, Route, Link, withRouter } from "react-router-dom";
import { AuthenticationContext } from "../auth/authcontext";

//import { Dashboard } from "./Dashboard";
import { Companies } from "./Companies";
import { CompanyProcess } from "./CompanyProcess";
import { Pos } from "./Pos";
import { PosMapping } from "./PosMapping";
import { Payroll } from "./Payroll";
import { PayrollMapping } from "./PayrollMapping";
import { AccoutingMapping } from "./AccoutingMapping";
//import CompanyForm from "./CompanyForm";
//import PosList from "./PosList";
//import PayrollList from "./PayrollList";
import { Reporting } from "./Reporting";
import { ReportingSettings } from "./ReportingSettings";

const linksData = [
  /*   {
    text: "Dashboard",
    to: "dashboard",
    icon: "Dashboard",
    component: Dashboard,
  }, */
  {
    text: "Companies",
    to: "companies",
    icon: "Business",
    component: Companies,
  },
  /*   { text: "POS", to: "poslist", icon: "AccountBalanceWalletRounded", component: PosList },
  {
    text: "Payroll",
    to: "payrolllist",
    icon: "Business",
    component: PayrollList,
  },
 */ { text: "POS", to: "pos", icon: "CloudUpload", component: Pos },
  {
    text: "Payroll",
    to: "payroll",
    icon: "CloudUpload",
    component: Payroll,
  },
  {
    text: "Reporting",
    to: "reporting/settings",
    icon: "ListAlt",
    component: ReportingSettings,
  },
];
/* 
const currentUser = JSON.parse(localStorage.getItem("user"));
  if (currentUser.user.username === "amrit") {
    linksData.push(
      {
        text: "Reporting",
        to: "reporting/settings",
        icon: "ListAlt",
        component: ReportingSettings,
      },
    )
  }
 */

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#424242",
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuItems: {
    textDecoration: "none",
    color: theme.palette.text.secondary,
  },
  noDecoration: {
    textDecoration: "none !important",
  },
}));

const Bar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  let auth = useContext(AuthenticationContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            {React.createElement(Icons["Menu"])}
          </IconButton>
          <Box>
            <Typography variant="h6" noWrap>
              Accounting System Engine mk. 0.2
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
          >
            <IconButton
              color="inherit"
              aria-label="Logout"
              onClick={auth.logout}
            >
              {React.createElement(Icons["ExitToApp"])}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        color="secondary"
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr"
              ? React.createElement(Icons["ChevronLeft"])
              : React.createElement(Icons["ChevronRight"])}
          </IconButton>
        </div>
        <Divider />
        <List key={"list"}>
          {linksData.map((item, index) => (
            <ListItem button key={index} component={Link} to={"/" + item.to}>
              <ListItemIcon>
                {React.createElement(Icons[item.icon])}
              </ListItemIcon>
              <ListItemText
                key={index.text + "text"}
                primary={
                  <Typography
                    key={index.text + "texttypo"}
                    variant="subtitle1"
                    className="text-white"
                  >
                    {item.text}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route key="home" exact path="/" component={Companies} />
          <Route exact path="/" component={Companies} />
          <Route exact path="/companies/:id" component={CompanyProcess} />
          <Route exact path="/pos/:id" component={PosMapping} />
          <Route exact path="/payroll/:id" component={PayrollMapping} />
          <Route exact path="/accounting/:id" component={AccoutingMapping} />
          <Route exact path="/companies/add" component={CompanyProcess} />
          <Route exact path="/reporting" component={Reporting} />
          {linksData.map((item) => (
            <Route
              key={item.text}
              exact
              path={"/" + item.to}
              component={item.component}
            />
          ))}
        </Switch>
      </main>
    </div>
  );
};
const AppTopBar = withRouter(Bar);
export default AppTopBar;
