import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  Container,
  Button,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const AccoutingMapping = (props) => {
  const classes = useStyles();
  const companyId = props.match.params.id;

  const [companyRelatedIds, setCompanyRelatedIds] = useState({
    pos_id: null,
    payroll_id: null,
    accounting_id: null,
  });
  const [workerPayroll, setWorkerPayroll] = useState({
    worker_to_worker_name_map: {},
    worker_to_pos_id_map: {},
    worker_to_accounting_id_map: {},
  });

  //data Mapped payroll
  const [dataMappedPayroll, setDataMappedPayroll] = useState(false);
  const [companyName, setCompanyName] = useState([]);
  //data mapping payroll
  // const [payrollMappingWorkers, setPayrollMappingWorkers] = useState({});
  const [accountingMappingWorkers, setAccountingMappingWorkers] = useState({});
  const [posMappingWorkers, setPosMappingWorkers] = useState({});
  //data mapping payroll accounting
  const [accountIDWorker, setAccountIDWorker] = useState([]);
  const [accountNameWorker, setAccountNameWorker] = useState({});
  //data mapping payroll pos
  const [posIDWorker, setPosIDWorker] = useState([]);
  const [posNameWorker, setPosNameWorker] = useState({});

  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);

  useEffect(() => {
    const accountRefresh = async () => {
      await backend
        .post(`${API_URL}data/api/refreshaccountingworkers/`, {
          refresh_pk_list: [companyId],
        })
        .then(({ data }) => {
          payrollRefresh();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const payrollRefresh = async () => {
      await backend
        .post(`${API_URL}data/api/refreshpayrollworkers/`, {
          refresh_pk_list: [companyId],
        })
        .then(({ data }) => {
          posRefresh();
          //         setSuccessPayroll(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const posRefresh = async () => {
      await backend
        .post(`${API_URL}data/api/refreshposworkers/`, {
          refresh_pk_list: [companyId],
        })
        .then(({ data }) => {
          //  extraMappingGet();
          companyGet();
          //         setSuccessPayroll(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const companyGet = async () => {
      await backend
        .get(`${API_URL}data/api/companies/${companyId}`)
        .then(({ data }) => {
          setCompanyRelatedIds({
            pos_id: data.pos,
            payroll_id: data.payroll,
            accounting_id: data.accounting,
          });
          setCompanyName(data.name);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (!isNaN(companyId)) {
      accountRefresh();
    }
  }, [companyId]);

  useEffect(() => {
    const accountGet = async () => {
      await backend
        .get(`${API_URL}data/api/accounting/${companyRelatedIds.accounting_id}`)
        .then(({ data }) => {
          let workerID = Object.keys(data.worker_to_worker_name_map);
          setAccountIDWorker(workerID);
          setAccountNameWorker(data.worker_to_worker_name_map);
        })

        .catch((error) => {
          console.log(error);
        });
    };

    const payrollGet = async () => {
      await backend
        .get(`${API_URL}data/api/payroll/${companyRelatedIds.payroll_id}`)
        .then(({ data }) => {
          setWorkerPayroll(data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const posGet = async () => {
      await backend
        .get(`${API_URL}data/api/pos/${companyRelatedIds.pos_id}`)
        .then(({ data }) => {
          let posWorkerID = Object.keys(data.worker_to_worker_name_map);
          setPosIDWorker(posWorkerID);
          setPosNameWorker(data.worker_to_worker_name_map);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (companyRelatedIds.payroll_id !== null) {
      payrollGet();
      accountGet();
      posGet();
    }
  }, [companyRelatedIds]);

  ///Payroll maping employees
  useEffect(() => {
    if (
      workerPayroll.hasOwnProperty("worker_to_accounting_id_map") &&
      workerPayroll.hasOwnProperty("worker_to_pos_id_map") &&
      !dataMappedPayroll
    ) {
      let accountingMapping = {};
      let posMapping = {};

      Object.keys(workerPayroll.worker_to_accounting_id_map).forEach((item) => {
        accountingMapping[item] =
          workerPayroll.worker_to_accounting_id_map[item];
      });
      setAccountingMappingWorkers(accountingMapping);

      Object.keys(workerPayroll.worker_to_pos_id_map).forEach((item) => {
        posMapping[item] = workerPayroll.worker_to_pos_id_map[item];
      });
      setPosMappingWorkers(posMapping);
    }
  }, [dataMappedPayroll, workerPayroll]);

  useEffect(() => {
    setDataMappedPayroll(() =>
      Object.keys(accountingMappingWorkers).length &&
      Object.keys(posMappingWorkers).length &&
      Object.keys(accountNameWorker).length
        ? true
        : false
    );
  }, [accountingMappingWorkers, posMappingWorkers, accountNameWorker]);

  const handleAccountingChange = (e, val, item) => {
    setAccountingMappingWorkers((prevState) => ({
      ...prevState,
      [item]: val,
    }));
  };

  const handlePosChange = (e, val, item) => {
    setPosMappingWorkers((prevState) => ({
      ...prevState,
      [item]: val,
    }));
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      Are you sure you want to update the payroll mapping
      <div align="right">
        <Button onClick={() => toggleConfirmModal()} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => sendRequest()}
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const toggleResponseModal = () => setResponseModal(!responseModal);

  const responseModalContent = (
    <div className={classes.modal}>
      {errorInfo.error ? (
        <>
          There was an error updating the payroll mapping
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Error:</b>
            <br />
            {errorInfo.message}
          </span>
        </>
      ) : (
        <>The payroll mapping has been successfully updated</>
      )}
      <div align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => resolveRequest()}
          className={classes.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const resolveRequest = () => {
    props.history.push("/companies");
  };

  const sendRequest = async () => {
    const mappingObj = {
      ...workerPayroll,
      worker_to_accounting_id_map: accountingMappingWorkers,
      worker_to_pos_id_map: posMappingWorkers,
    };
    // console.log(mappingObj, accountingMappingWorkers, posMappingWorkers);
    await backend
      .put(
        `${API_URL}data/api/payroll/${companyRelatedIds.payroll_id}`,
        mappingObj
      )
      .then((response) => {
        toggleResponseModal();
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
        toggleResponseModal();
      });
  };

  if (!dataMappedPayroll) {
    return (
      <Grid
        container
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} sm={12}>
          <CircularProgress />
        </Grid>
        <Grid item xs={12} sm={12}>
          Loading the most recent mapping information
        </Grid>
      </Grid>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Typography align="center" variant="h6" mt={5}>
        {companyName}
      </Typography>

      <Typography color="primary" component="h4">
        Match the name with the corresponding name for the POS and Accounting
        system.
      </Typography>
      <Modal open={confirmModal} onClose={toggleConfirmModal}>
        {confirmModalContent}
      </Modal>
      <Modal open={responseModal} onClose={toggleResponseModal}>
        {responseModalContent}
      </Modal>
      <div className={classes.paper}>
        <form className={classes.form} margin="dense" autoComplete="off">
          {Object.keys(workerPayroll.worker_to_worker_name_map).map((item) => (
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
              key={item}
            >
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  required={true}
                  id="outlined-read-only-input"
                  variant="outlined"
                  name="categories"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={item && workerPayroll.worker_to_worker_name_map[item]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={posIDWorker}
                  getOptionLabel={(option) => posNameWorker[option]}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="POS Name" />
                  )}
                  value={posMappingWorkers && posMappingWorkers[item]}
                  onChange={(e, value) => handlePosChange(e, value, item)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={accountIDWorker}
                  getOptionLabel={(option) => `${accountNameWorker[option]}`}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Accounting Name" />
                  )}
                  value={
                    accountingMappingWorkers && accountingMappingWorkers[item]
                  }
                  onChange={(e, value) =>
                    handleAccountingChange(e, value, item)
                  }
                />
              </Grid>
            </Grid>
          ))}
        </form>
        <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleConfirmModal}
          >
            <Typography variant="subtitle1" className="text-white">
              Add Mapping
            </Typography>
          </Button>
        </Grid>
      </div>
    </Container>
  );
};
