import React, { useState, Fragment, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Modal,
} from "@material-ui/core";

import { PayrollForm } from "./PayrollForm";
import { PayrollPeriods } from "./PayrollPeriods";

import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const Payroll = (props) => {
  const classes = useStyles();
  const steps = ["Select Company", "Select periods for company"];

  const [activeStep, setActiveStep] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [payroll, setPayroll] = useState({ companies: [] });
  const [periods, setPeriods] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [finishedFlag, setFinishedFlag] = useState(false);

  const handleChange = (idx) => (e) => {
    const { name, value } = e.target;

    setPayroll((prevState) => {
      if (isNaN(idx)) return { ...prevState, [name]: value };

      return {
        ...prevState,
        companies: prevState.companies.map((item, index) => {
          if (idx !== index) return item;
          return { ...item, [name]: value };
        }),
      };
    });
  };

  useEffect(() => {
    const getPayroll = async () => {
      await backend.get(`${API_URL}data/api/payroll`).then(({ data }) => {
        const dataMap = data.length ? data : data.results;

        const dataPeriods = dataMap.map((item) => {
          const periodsPks = Object.keys(item.periods);
          const periodsArray = periodsPks.map((periodPk) => ({
            pk: periodPk,
            start_date: item.periods[periodPk].start_date,
            end_date: item.periods[periodPk].end_date,
            check_date: item.periods[periodPk].check_date,
            status: item.periods[periodPk].status,
          }));
          return {
            pk: item.pk,
            periods: periodsArray,
          };
        });
        setPeriods(dataPeriods);
      });
    };

    getPayroll();
  }, []);

  useEffect(() => {
    const getCompanies = async () => {
      await backend
        .get(`${API_URL}data/api/companies?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => {
            const periodsArray = periods.filter(
              (period) => period.pk === item.payroll
            );
            return {
              pk: item.pk,
              name: item.name,
              payroll_pk: item.payroll,
              periods: "",
              availablePeriods: periodsArray.length
                ? periodsArray[0].periods
                : [],
            };
          });
          setCompanies(dataMap);
        });
    };
    if (periods.length) {
      getCompanies();
    }
  }, [periods]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PayrollForm
            {...props}
            payroll={payroll}
            companies={companies}
            handleChange={handleChange}
          />
        );
      case 1:
        return (
          <PayrollPeriods
            {...props}
            companies={companies}
            payroll={payroll}
            handleChange={handleChange}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Are you sure to export all payroll to the accounting system?
          <div align="right">
            <Button
              onClick={() => toggleConfirmModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendRequest()}
              className={classes.button}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div align="center">
            <CircularProgress />
            <div>
              Exporting payroll <br />
              This may take some time relative to the number of payroll.
            </div>
          </div>
          {errorInfo.error ? (
            <>
              <br />
              <br />
              There was an error while exporting the payroll
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  wordBreak: "break-word",
                  maxHeight: 160,
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                <b>Error:</b>
                <br />
                {errorInfo.message}
              </span>
              <div align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resolveRequest()}
                  className={classes.button}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          The payroll have been successfully exported
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const resolveRequest = () => {
    setFinishedFlag(false);
    toggleConfirmModal();
    props.history.push("/companies");
  };

  const sendRequest = async () => {
    setLoadingFlag(true);
    let companiesArray = payroll.companies.map((item) => ({
      company: item.pk,
      periods: [item.periods],
    }));
    await backend
      .post(`${API_URL}data/api/sendpayroll/`, companiesArray)
      .then((response) => {
        setLoadingFlag(false);
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  return (
    <div>
      <Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Send Payroll
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Modal open={confirmModal} onClose={toggleConfirmModal}>
              {confirmModalContent}
            </Modal>
            <Fragment>
              {activeStep === steps.length ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </Paper>
        </main>
      </Fragment>
    </div>
  );
};
