import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  Container,
  Button,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const PosMapping = (props) => {
  const classes = useStyles();
  const companyId = props.match.params.id;

  const [companyRelatedIds, setCompanyRelatedIds] = useState({
    pos_id: null,
    payroll_id: null,
    accounting_id: null,
  });
  const [posCategories, setPosCategories] = useState({
    category_to_account_number_map: {},
    category_to_category_name_map: {},
  });
  const [accountNumber, setAccountNumber] = useState([]);
  const [accountName, setAccountName] = useState({});
  const [accountNumberExtra, setAccountNumberExtra] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [posMapping, setPosMapping] = useState({});
  const [dataMapped, setDataMapped] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);

  useEffect(() => {
    const extraMappingGet = async () => {
      await backend
        .get(`${API_URL}data/api/extramappings/`)
        .then(({ data }) => {
          setAccountNumberExtra(data.payroll);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const accountRefresh = async () => {
      await backend
        .post(`${API_URL}data/api/refreshaccounts/`, {
          refresh_pk_list: [companyId],
        })
        .then(({ data }) => {
          posRefresh();
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const posRefresh = async () => {
      await backend
        .post(`${API_URL}data/api/refreshpos/`, {
          refresh_pk_list: [companyId],
        })
        .then(({ data }) => {
          extraMappingGet();
          companyGet();
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const companyGet = async () => {
      await backend
        .get(`${API_URL}data/api/companies/${companyId}`)
        .then(({ data }) => {
          setCompanyRelatedIds({
            pos_id: data.pos,
            payroll_id: data.payroll,
            accounting_id: data.accounting,
          }); setCompanyName(data.name);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (!isNaN(companyId)) {
      accountRefresh();
    }
  }, [companyId]);

  useEffect(() => {
    const accountGet = async () => {
      await backend
        .get(`${API_URL}data/api/accounting/${companyRelatedIds.accounting_id}`)
        .then(({ data }) => {
          let dataMap = Object.keys(data.accounts);
          let dataExtra = {};
          accountNumberExtra.forEach((item) => (dataExtra[item] = item));
          setAccountName({ ...data.accounts, ...dataExtra });
          setAccountNumber([...dataMap, ...accountNumberExtra]);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const posGet = async () => {
      await backend
        .get(`${API_URL}data/api/pos/${companyRelatedIds.pos_id}`)
        .then(({ data }) => {
          setPosCategories(data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (companyRelatedIds.pos_id !== null && accountNumberExtra.length) {
      posGet();
      accountGet();
    }
  }, [accountNumberExtra, companyRelatedIds]);

  useEffect(() => {
    if (
      posCategories.hasOwnProperty("category_to_account_number_map") &&
      !dataMapped
    ) {
      let mapping = {};
      Object.keys(posCategories.category_to_category_name_map).forEach(
        (item) => {
          mapping[item] = posCategories.category_to_account_number_map[item];
        }
      );
      setPosMapping(mapping);
    }
  }, [dataMapped, posCategories]);

  useEffect(() => {
    setDataMapped(() =>
      Object.keys(posMapping).length && Object.keys(accountName).length
        ? true
        : false
    );
  }, [accountName, posMapping]);

  const handleChange = (e, val, item) => {
    setPosMapping((prevState) => ({
      ...prevState,
      [item]: val,
    }));
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      Are you sure you want to update the pos mapping
      <div align="right">
        <Button onClick={() => toggleConfirmModal()} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => sendRequest()}
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const toggleResponseModal = () => setResponseModal(!responseModal);

  const responseModalContent = (
    <div className={classes.modal}>
      {errorInfo.error ? (
        <>
          There was an error updating the pos mapping
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Error:</b>
            <br />
            {errorInfo.message}
          </span>
        </>
      ) : (
        <>The pos mapping has been successfully updated</>
      )}
      <div align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => resolveRequest()}
          className={classes.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const resolveRequest = () => {
    props.history.push("/companies");
  };

  const sendRequest = async () => {
    const mappingObj = {
      ...posCategories,
      category_to_account_number_map: posMapping,
    };
    await backend
      .put(`${API_URL}data/api/pos/${companyRelatedIds.pos_id}`, mappingObj)
      .then((response) => {
        toggleResponseModal();
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
        toggleResponseModal();
      });
  };

  if (!dataMapped) {
    return (
      <Grid
        container
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} sm={12}>
          <CircularProgress />
        </Grid>
        <Grid item xs={12} sm={12}>
          Loading the most recent mapping information
        </Grid>
      </Grid>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Typography  align="center" variant="h6" mt={5}>{companyName}</Typography>
      <Typography  variant="subtitle1" mt={2}>
        Match the category with an account number.
      </Typography>
      <Modal open={confirmModal} onClose={toggleConfirmModal}>
        {confirmModalContent}
      </Modal>
      <Modal open={responseModal} onClose={toggleResponseModal}>
        {responseModalContent}
      </Modal>
      <div className={classes.paper}>
        <form className={classes.form} margin="dense" autoComplete="off">
          {Object.keys(posCategories.category_to_category_name_map).map(
            (item) => (
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
                key={item}
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    size="small"
                    required={true}
                    id="outlined-read-only-input"
                    variant="outlined"
                    name="categories"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={
                      item && posCategories.category_to_category_name_map[item]
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={accountNumber}
                    getOptionLabel={(option) =>
                      option + " (" + accountName[option]+ ")"
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Account Number" />
                    )}
                    value={posMapping && posMapping[item]}
                    onChange={(e, value) => handleChange(e, value, item)}
                  />
                </Grid>
              </Grid>
            )
          )}
        </form>
        <Grid item xs={12} sm={12} style={{ marginTop: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleConfirmModal}
          >
            <Typography variant="subtitle1" className="text-white">
              Add Mapping
            </Typography>
          </Button>
        </Grid>
      </div>
    </Container>
  );
};
