import "./App.css";
import React from "react";
import { AuthProvider } from "./auth/authcontext";
import { ThemeProvider } from "@material-ui/core";

import CustomTheme from "./CustomTheme";
import Splash from "./components/splash";

import {
  BrowserRouter as Router,
} from "react-router-dom";

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={CustomTheme}>
        <Router>
          <Splash />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
