import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { backend, API_URL } from "../backend_api";
import { Link } from "react-router-dom";
import { jsonToCSV } from "react-papaparse";

import {
  CssBaseline,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  TextField,
  MenuItem,
  Modal,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { lighten, makeStyles } from "@material-ui/core/styles";

import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import LoopIcon from "@material-ui/icons/Loop";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  stickyHeader: {
    zIndex: "4 !important",
    "& [data-sticky-td=true]": {
      zIndex: "5 !important",
    },
  },
  bgRows: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  bgCells: {
    body: {
      background: "inherit",
    },
    "[data-sticky-last-left-td]": {
      "box-shadow": "2px 0px 3px #ccc",
    },
    "[data-sticky-first-right-td]": {
      "box-shadow": "-2px 0px 3px #ccc",
    },
  },
  bgCellsBody: {
    "[data-sticky-td=true]": {
      zIndex: 2,
      background: "#fff",
    },
  },
  tableBody: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const InfiniteTable = (props) => {
  const classes = useStyles();
  const {
    columns,
    dataMapByColumn,
    editable,
    deletable,
    filterable,
    label,
    fetchingModal,
  } = props;
  const staticData = props.data;

  const [apiep, setApiEp] = useState(props.apiep);
  const [data, setData] = useState([]);
  const [dataFull, setDataFull] = useState([]);
  const [dataFullAsync, setDataFullAsync] = useState(false);
  const [fetchData, setFetchData] = useState({ fetch: true, all: false });
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selected, setSelected] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [query, setQuery] = useState("");
  const [queryColumn, setQueryColumn] = useState("");
  const [exportModal, setExportModal] = useState(false);
  const [fetchModal, setFetchModal] = useState(false);
  const [fetchFlag, setFetchFlag] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [finishedFlag, setFinishedFlag] = useState(false);
  const [pendingFetch, setPendingFetch] = useState(false);

  useEffect(() => {
    const makeData = async () => {
      let dataMapping = { count: 0, next: "", results: [] };
      let endpoint = apiep;
      let fetchAllInEndpoint = false;

      if (endpoint == null || endpoint === "") return dataMapping;

      let keys = ["pk"];
      columns.forEach((columnData) => {
        if (columnData.hasOwnProperty("id")) {
          keys.push(columnData.id);
        }
        if (
          columnData.hasOwnProperty("editablePk") &&
          !keys.includes(columnData.editablePk)
        ) {
          keys.push(columnData.editablePk);
        }
        if (
          columnData.hasOwnProperty("statusPk") &&
          !keys.includes(columnData.statusPk)
        ) {
          keys.push(columnData.statusPk);
        }
        if (columnData.hasOwnProperty("title")) {
          keys.push(columnData.title);
        }
      });

      if (endpoint.includes("http://") && API_URL.includes("https://")) {
        const API_URL_TMP = API_URL.replace("https://", "http://");
        endpoint = endpoint.replace(API_URL_TMP, "");
      } else {
        endpoint = endpoint.replace(API_URL, "");
      }

      fetchAllInEndpoint = endpoint.includes("no_page=1");

      if (fetchData.all || fetchAllInEndpoint) {
        let endpointBuilt = fetchAllInEndpoint
          ? endpoint
          : `${endpoint.split("?")[0]}?no_page=1`;

        await backend
          .get(`${API_URL}${endpointBuilt}`)
          .then(({ data }) => {
            let dataArray = [];
            if (Array.isArray(data)) {
              dataArray = data;
            } else {
              dataArray = data.results;
            }

            if (dataArray.length > 0) {
              const dataMap = dataArray.map((item) => {
                const obj = {};
                keys.forEach((key) => {
                  obj[key] =
                    typeof item[key] === "object" && item[key] !== null
                      ? item[key].pk
                      : item[key];
                });

                return obj;
              });
              dataMapping = {
                count: dataMap.length,
                next: "",
                results: dataMap,
              };
            }
          })
          .catch((e) => {
            console.log("error", e);
          });
      } else {
        await backend
          .get(`${API_URL}${endpoint}`)
          .then(({ data }) => {
            if (data.results.length > 0) {
              const dataMap = data.results.map((item) => {
                const obj = {};
                keys.forEach((key) => {
                  obj[key] =
                    typeof item[key] === "object" && item[key] !== null
                      ? item[key].pk
                      : item[key];
                });

                return obj;
              });
              dataMapping = {
                count: data.count,
                next: data.next,
                results: dataMap,
              };
            }
          })
          .catch((e) => {
            console.log("error", e);
          });
      }

      setPage(0);

      return dataMapping;
    };

    if (Array.isArray(staticData)) {
      setData(staticData);
      setDataFull(staticData);
    } else if (fetchData.fetch) {
      let fullDataAsync = false;
      let nextApiep = null;
      if (!pendingFetch) {
        if (!fetchData.all) {
          setPendingFetch(true);
        }
        if (fetchingModal && !fetchFlag) {
          setFetchModal(true);
          setFetchFlag(true);
        }
        makeData()
          .then(({ count, next, results }) => {
            nextApiep = next;
            if (fetchData.all) {
              setData(results);
              setDataFull(results);
              setPendingFetch(true);
            } else {
              setData((prevState) =>
                count === prevState.length
                  ? prevState
                  : [...prevState, ...results]
              );
              setDataFull((prevState) =>
                count === prevState.length
                  ? prevState
                  : [...prevState, ...results]
              );
            }
            setApiEp(nextApiep);
          })
          .catch((e) => {
            console.log("error", e);
          })
          .finally(() => {
            setPendingFetch(false);
            if (
              apiep &&
              !apiep.includes("from_date=") &&
              !apiep.includes("to_date=")
            ) {
              fullDataAsync = true;
              if (!dataFullAsync && nextApiep !== null && nextApiep !== "") {
                setDataFullAsync(true);
                setFetchData({ fetch: true, all: true });
              }
            }
            setFetchModal(false);
          });
      }
      if (!fullDataAsync) {
        setFetchData({ fetch: false, all: fetchData.all });
      }
    }
  }, [
    apiep,
    columns,
    dataFullAsync,
    fetchData,
    fetchFlag,
    fetchingModal,
    pendingFetch,
    staticData,
  ]);

  useEffect(() => {
    const filterData = () => {
      if (filtering) {
        if (query) {
          if (queryColumn !== "") {
            setData(
              dataFull.filter((item) =>
                item[queryColumn] === undefined || item[queryColumn] === null
                  ? false
                  : item[queryColumn].toString().toLowerCase().includes(query)
              )
            );
          } else {
            let filteredData = [];
            dataFull.forEach((item) => {
              const match = columns.some((column) =>
                item[column.id] === undefined || item[column.id] === null
                  ? false
                  : item[column.id].toString().toLowerCase().includes(query)
              );

              if (match) filteredData.push(item);
            });
            setData(filteredData);
          }
        } else {
          setData(dataFull);
        }
      }
    };

    filterData();
  }, [columns, dataFull, filtering, query, queryColumn]);

  const handleQuery = (e) => {
    /* clearTimeout(delayDebounceFn);
    delayDebounceFn(e); */
    setQuery(e.target.value.toLowerCase());
  };

  const handleQueryColumn = (e) => {
    setQueryColumn(e.target.value);
  };

  /* const delayDebounceFn = (e) =>
    setTimeout(() => {
      setQuery(e.target.value.toLowerCase());
    }, 500); */

  const doFetchData = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      !pendingFetch
    ) {
      setFetchData({ fetch: true, all: false });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    if (!deletable) return;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const cellFormat = (value) => {
    switch (typeof value) {
      case "string":
        const dateVal = new Date(value.replace(" ", ""));
        const numVal = +value;
        if (isNaN(numVal) && !isNaN(dateVal.getTime())) {
          return dateVal.toISOString().split("T")[0];
        } else {
          return value;
        }
      case "number":
        return value;
      case "boolean":
        return value.toString();
      default:
        return value;
    }
  };

  const exportData = () => {
    setLoadingFlag(true);
    const results = jsonToCSV(data);
    const blob = new Blob([`${results}`], {
      type: "text/csv;charset=utf-8;",
    });
    const cDate = new Date(
      new Date().toString().split("GMT")[0] + " UTC"
    ).toISOString();
    const filename = `${label} ${cDate}`;
    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", `${filename}.csv`);
    link.click();
    link.remove();
    setLoadingFlag(false);
    setFinishedFlag(true);
  };

  const resolveRequest = () => {
    toggleExportModal();
    setFinishedFlag(false);
  };

  const toggleExportModal = () => setExportModal(!exportModal);

  const exportModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Would you like to export the <b>current</b> table data?
          <div align="right">
            <Button
              onClick={() => toggleExportModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => exportData()}
              className={classes.button}
            >
              Export
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <div>Exporting...</div>
          </div>
        </>
      ) : (
        <>
          Table data has been exported to CSV successfully check your downloads
          section.
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const fetchModalContent = (
    <div className={classes.modal}>
      <div align="center">
        <CircularProgress />
        <div>
          {fetchingModal ? fetchingModal.title : "Fetching"}
          <br />
          {fetchingModal
            ? fetchingModal.description
            : "This may take some time relative to the number of records."}
        </div>
      </div>
    </div>
  );

  const EnhancedTableHead = (props) => {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {deletable ? (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all" }}
              />
            </TableCell>
          ) : (
            <></>
          )}
          {columns.map((headCell, index) => (
            <TableCell
              key={index}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string,
    rowCount: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, filtering } = props;

    return (
      <Toolbar
        key="toolbar"
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0 || filtering,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Fragment key="filtering-fragment-container">
            {filtering ? (
              <Grid
                key="filtering-container"
                container
                spacing={2}
                alignItems="center"
              >
                <Grid
                  key="search-column-container"
                  item
                  xs={3}
                  sm={3}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="search-column"
                      style={{ marginTop: -4 }}
                    >
                      Search by column
                    </InputLabel>
                    <Select
                      key="search-column"
                      id="search-column"
                      input={<Input />}
                      name="search-column"
                      defaultValue={queryColumn}
                      onChange={handleQueryColumn}
                    >
                      <MenuItem key={"all"} value="">
                        <em>All</em>
                      </MenuItem>
                      {columns.map((headCell, index) => (
                        <MenuItem key={"column-" + index} value={headCell.id}>
                          {headCell.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid key="search-text-container" item xs={9} sm={9}>
                  <TextField
                    fullWidth
                    autoFocus
                    key="search-text"
                    size="small"
                    id="search-text"
                    variant="outlined"
                    label="Search "
                    name="search-text"
                    defaultValue={query}
                    onChange={handleQuery}
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {label}
              </Typography>
            )}
          </Fragment>
        )}
        <Tooltip title="Export list">
          <IconButton
            aria-label="export list"
            onClick={() => {
              toggleExportModal();
            }}
          >
            <SaveAltIcon />
          </IconButton>
        </Tooltip>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            {filterable ? (
              <Tooltip title="Filter list">
                <IconButton
                  aria-label="filter list"
                  onClick={(e) => {
                    setFiltering(!filtering);
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    filtering: PropTypes.bool.isRequired,
  };

  return (
    <Fragment key="fragment-container">
      <CssBaseline />
      <Modal open={fetchModal}>{fetchModalContent}</Modal>
      <Modal open={exportModal} onClose={toggleExportModal}>
        {exportModalContent}
      </Modal>
      <div key="div-container" className={classes.root}>
        <Paper key="paper-container" className={classes.root}>
          <EnhancedTableToolbar
            key="enhanced-toolbar"
            numSelected={selected.length}
            filtering={filtering}
          />
          <TableContainer className={classes.container} onScroll={doFetchData}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label="sticky enhanced table"
              size="medium"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => {
                    const isItemSelected = isSelected(rowIndex);
                    const labelId = `enhanced-table-checkbox-${rowIndex}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, rowIndex)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={rowIndex}
                        selected={isItemSelected}
                        className={classes.tableBody}
                      >
                        {deletable ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        {columns.map((column, columnIndex) => (
                          <TableCell key={rowIndex + "-" + columnIndex}>
                            {editable &&
                            column.editable &&
                            column.editablePk ? (
                              <Link
                                to={{
                                  pathname: `${column.editable}/${
                                    row[column.editablePk]
                                  }`,
                                  state: { data: row },
                                }}
                              >
                                {dataMapByColumn === "id"
                                  ? row[column.id]
                                  : row[columnIndex]}
                              </Link>
                            ) : dataMapByColumn === "id" ? (
                              cellFormat(
                                typeof row[column.id] === "object" &&
                                  row[column.id] !== null
                                  ? row[column.id].pk
                                  : row[column.id]
                              )
                            ) : (
                              cellFormat(row[columnIndex])
                            )}
                            {column.statusPk !== null &&
                            column.statusPk !== undefined &&
                            row[column.statusPk] !== undefined &&
                            row[
                              dataMapByColumn === "id" ? column.id : columnIndex
                            ] !== null &&
                            row[
                              dataMapByColumn === "id" ? column.id : columnIndex
                            ] !== undefined ? (
                              typeof row[column.statusPk] === "boolean" ? (
                                row[column.statusPk] ? (
                                  <CheckCircleIcon
                                    className="text-success"
                                    style={{ marginLeft: 5 }}
                                  />
                                ) : (
                                  <CancelIcon
                                    className="text-danger"
                                    style={{ marginLeft: 5 }}
                                  />
                                )
                              ) : row[column.statusPk] === "green" ? (
                                <CheckCircleIcon
                                  className="text-success"
                                  style={{ marginLeft: 5 }}
                                />
                              ) : row[column.statusPk] === "yellow" ? (
                                <ErrorIcon
                                  className="text-warning"
                                  style={{ marginLeft: 5 }}
                                />
                              ) : (
                                <CancelIcon
                                  className="text-danger"
                                  style={{ marginLeft: 5 }}
                                />
                              )
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            className={classes.root}
            style={{ padding: "0px 10px" }}
          >
            <Grid item xs={1} sm={1}>
              <Tooltip title="Load all data">
                <IconButton
                  aria-label="load all data"
                  onClick={(e) => {
                    if (
                      apiep !== null &&
                      apiep !== "" &&
                      !apiep.includes("from_date") &&
                      !apiep.includes("to_date")
                    ) {
                      setFetchData({ fetch: true, all: true });
                      setPendingFetch(false);
                    }
                  }}
                >
                  <LoopIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11}>
              <TablePagination
                rowsPerPageOptions={[25, 75, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Fragment>
  );
};

export default InfiniteTable;
