import {
  backend,
  API_URL,
  AUTH_REFRESH_ENDPOINT,
  AUTH_OBTAIN_ENDPOINT,
} from "../backend_api";

export const refreshToken = () => {
  let currUser = JSON.parse(localStorage.getItem("user"));
  let getUserFormData = new FormData();
  getUserFormData.append("refresh", currUser.refresh);

  let data = JSON.stringify(Object.fromEntries(getUserFormData));

  return new Promise((resolve, reject) => {
    backend
      .post(`${API_URL + AUTH_REFRESH_ENDPOINT}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUser = () => {
  let currUser = JSON.parse(localStorage.getItem("user"));

  if (!currUser) {
    // IF no user in localStorage then the user must enter their creds
    return Promise.resolve(null);
  }

  // Get Expiry Time of current access token
  let currDate = new Date();
  let duration = currUser.expires_in * 1000; // convert s to ms
  let diff = currDate.getTime() - currUser.lastRefresh;

  if (diff >= duration) {
    // Refresh Token
    refreshToken()
      .then((response) => {
        currUser.access = response.data.access;
        currUser.lastRefresh = new Date().getTime();
        localStorage.setItem("user", JSON.stringify(currUser));
        backend.defaults.headers.common["Authorization"] =
          "Bearer " + currUser.access;

        ////////// POSSIBLY GET CURRENT URL 34////////
        //https://javascript.plainenglish.io/all-in-one-tutorial-on-how-to-create-the-authentication-part-of-your-react-app-2530e7435629
      })
      .catch((error) => {
        logout();
      });
  } else {
    // Don't Need to refresh
    backend.defaults.headers.common["Authorization"] =
      "Bearer " + currUser.access;
    backend.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // CAREFUL! Below could cause infinite loop
        // if (error.response.status === 401) {
        //   return refreshToken()
        //     .then((response) => {
        //       currUser.access = response.data.access;
        //       currUser.lastRefresh = new Date().getTime();
        //       localStorage.setItem("user", JSON.stringify(currUser));
        //       backend.defaults.headers.common["Authorization"] =
        //         "JWT " + response.access;

        //       // Get Original Request
        //       const config = error.config;
        //       config.headers.Authorization = "JWT " + response.access;

        //       // Resend original request
        //       return new Promise((resolve, reject) => {
        //         backend
        //           .request(config)
        //           .then((response) => {
        //             resolve(response);
        //           })
        //           .catch((error) => {
        //             reject(error);
        //           });
        //       });
        //     })
        //     .catch((error) => {
        //       // If refresh token failed logout
        //       Promise.reject(error);
        //       logout();
        //     });
        // }
        logout();
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );

    ////////// POSSIBLY GET CURRENT URL Line 98////////
    //https://javascript.plainenglish.io/all-in-one-tutorial-on-how-to-create-the-authentication-part-of-your-react-app-2530e7435629

    //return backend.get("/users/current/url").catch(error => {
    //    logout();
    //    throw error;
    //});
    //alert(currUser);
    //return Promise.resolve(currUser);

    return Promise.resolve({ data: JSON.stringify(currUser) });
  }
};

export const login = (username, password) => {
  let loginFormData = new FormData();
  loginFormData.append("username", username);
  loginFormData.append("password", password);

  let data = JSON.stringify(Object.fromEntries(loginFormData));

  return new Promise((resolve, reject) => {
    backend
      .post(`${API_URL + AUTH_OBTAIN_ENDPOINT}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        response.data.lastRefresh = new Date().getTime();
        localStorage.setItem("user", JSON.stringify(response.data));

        getUser()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = () => {
  localStorage.removeItem("user");
  return Promise.resolve();
};
