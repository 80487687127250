import {
  useTheme,
  Input,
  Typography,
  Container,
  makeStyles,
  CssBaseline,
  Grid,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 385,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const getStyles = (name, pos, theme) => {
  return {
    fontWeight:
      pos.companies.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PosForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { handleChange, companies, pos } = props;

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography color="primary" component="h4">
            Select company
          </Typography>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel shrink id="companyLabel">
                    Company
                  </InputLabel>
                  <Select
                    fullWidth
                    id="outlined-select-companies"
                    multiple
                    label="Company"
                    onChange={handleChange}
                    input={<Input />}
                    name="companies"
                    value={pos && pos.companies}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((item) => (
                          <Chip
                            key={item.pk + "chip"}
                            label={item.name}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {companies.map((option) => (
                      <MenuItem
                        key={option.pk}
                        value={option}
                        style={getStyles(option.name, pos, theme)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};
