import React, { Fragment } from "react";
import {
  Typography,
  Container,
  TextField,
  makeStyles,
  CssBaseline,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 385,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const CompanyAccounting = (props) => {
  const classes = useStyles();
  const { handleChange, company, accounting } = props;

  return (
    <Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography color="primary" component="h4">
            Accounting Information
          </Typography>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12}>
                <InputLabel shrink id="posName">
                  Accounting Name
                </InputLabel>
                <Select
                  className={classes.formControl}
                  key="select"
                  name="accounting_name"
                  label="Accounting Name"
                  value={company && company.accounting_name}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>- None -</em>
                  </MenuItem>
                  {accounting.map((item) => (
                    <MenuItem key={item} value={item}>
                      <b>{item}</b>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    inputProps: {
                      autoComplete: "off",
                    },
                  }}
                  size="small"
                  variant="outlined"
                  label="Accounting Username: "
                  name="accounting_username"
                  value={company && company.accounting_username}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    inputProps: {
                      type: "password",
                      autoComplete: "new-password",
                    },
                  }}
                  size="small"
                  variant="outlined"
                  label="Accounting Password: "
                  name="user_password"
                  value={company && company.user_password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Entity ID: "
                  name="accounting_entity_id"
                  value={company && company.accounting_entity_id}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Company ID: "
                  name="accounting_company_id"
                  value={company && company.accounting_company_id}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Sales Journal ID: "
                  name="sales_journal_id"
                  value={company && company.sales_journal_id}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Payroll Journal ID: "
                  name="payroll_journal_id"
                  value={company && company.payroll_journal_id}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </Fragment>
  );
};
