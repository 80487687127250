import React, { useState, useContext } from "react";
import { AuthenticationContext } from "../auth/authcontext";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

const LoginModal = (props) => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const authc = useContext(AuthenticationContext);

  const toggle = () => setModal(!modal);

  const Login = (event) => {
    event.preventDefault();
    authc.login(Object.values({ username }), Object.values({ password }));
    toggle();
  };

  return (
    <div className='a'>
      <Button color="primary" onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className} style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: 500}}>
        <ModalHeader toggle={toggle}>Login</ModalHeader>
        <ModalBody>
          <Container className="loginform-container">
            <h2>{"Sign In"}</h2>
            <Form className="loginform-form">
              <Col>
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    type="plaintext"
                    name="username"
                    id="exampleusername"
                    placeholder="username"
                    onChange={(event) => setUsername(event.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="examplePassword">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="examplePassword"
                    placeholder="********"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </FormGroup>
              </Col>
            </Form>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={Login}>
            Submit
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LoginModal;
